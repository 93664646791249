export default {
    nav: {
        properties: 'Properties',
        owners: 'Owners',
        users: 'Users',
        roles: 'Roles',
        attributes: 'Attributes',
        reports: 'Reports',
        login: 'Login',
        logout: 'Logout',
    },
    patients: {
        patients: 'patients',
        actions: 'Actions',
        name: 'Name',
        surname: 'Surname',
        id_number: 'ID Number',
        date_of_birth: 'D.O.B',
        gender: 'Gender',
        locality: 'Locality',
        next_of_kin: 'Next of Kin',
        next_of_kin_details: 'Next of Kin Details',
        phone: 'Phone',
        building: 'Building',
        street: 'Street',
        city: 'City',
        country: 'Country',
        zip: 'ZIP',
        third_party: 'Third Party',
        ward: 'Ward',
        admitted_on: 'Admitted On',
        date_of_admission: 'Date of Admission',
        date_of_admission_short: 'Date of Adm',
        admitted_from: 'Admitted From',
        max_daily_bookings: 'Max Daily Bookings',
        media: 'Media',
        media_permission: 'Media Permission',
        add_patient: 'Add Patient',
        update_patient: 'Update Patient',
        search_patients: 'Search patients',
        discharge_patient: 'Discharge patient',
        patient_details: 'Patient Details',
        other_details: 'Other Details',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve patients',
        error_create: 'Failed to create patient',
        error_update: 'Failed to update patient',
        error_delete: 'Failed to delete patient',
        error_discharged: 'Failed to discharge patient',
        success_created: 'Patient has been created',
        success_updated: 'Patient has been updated',
    },
    properties: {
        properties: 'properties',
        search_properties: 'search properties',
        add_property: 'Add Property',
        add_rent: 'Add Rent',
        status: 'Status',
        reference: 'Reference',
        location: 'Location',
        no_of_bedrooms: 'No of Bedrooms',
        price: 'Price',
        actions: 'Actions',
        property_owner: 'Owner',
        owner_details: 'Owner Details',
        property_details: 'Property Details',
        images: 'Images',
        upload: 'Upload',
        download: 'Download Images',
        name: 'Name',
        contact_number: 'Contact Number',
        email: 'Email',
        address: 'Address',
        locality: 'Locality',
        post_code: 'Post Code',
        google_maps_link: 'Location Link',
        available_from: 'Property available from',
        date: 'Date',
        property_condition: 'Property Condition',
        property_type: 'Property Type',
        monthly_rent: 'Monthly Rent',
        deposit: 'Deposit',
        property_attributes: 'Property Attributes',
        floor: 'Floor',
        bedrooms: 'Bedrooms',
        sleeps: 'Sleeps',
        bathrooms: 'Bathrooms',
        air_conditioner: 'Air Conditioner/s',
        edit_property: 'Edit Property',
        entries: 'Entries',
        rent_id: 'Rent ID',
        rent: 'Rent',
        client: 'Client',
        full_name: 'Full Name',
        starts_at: 'Starts at',
        ends_at: 'Ends at',
        deposit_expires_at: 'Deposit Contract Expires at',
        expires_at: 'Expires at',
        agent: 'Agent',
        available: 'Available',
        pending: 'Pending',
        not_available: 'Not Available',
        upcoming: 'Upcoming',
        rent_x_details: 'Rent {x} Details',
        contract_type_primary: 'Pre Contract',
        contract_type_final: 'Final Contract',
        final_contract: 'Final Contract',
        type: 'Type',
        confirmed_on: 'Confirmed On',
        expires_on: 'Expires On',
        verify: 'Verify',
        unverify: 'Unverify',
        verified_only: 'Verified Only',
        unverified_only: 'Unverified Only',
        final_contract_text: 'Please upload a copy of the Final Contract',
        final_contract_saved: 'Final Contract has been saved',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: '',
        prompt_rent_delete: 'Are you sure you want to delete?',
        prompt_rent_delete_subtext: '',
        success_rent_created: 'Rent has been created',
        success_created: 'Property has been created',
        success_updated: 'Property has been updated',
        error_documents_retrieve: 'Failed to retrieve documents',
        error_create: 'Failed to create property',
        error_download_document: 'Failed to download document',
        edit_contract_x: 'Edit Contract {x}',
        contract_saved: 'Contract has been saved',
        error_contract_save: 'Failed to save contract',
        error_rent_created: 'Failed to create rent',
        error_delete_rent: 'Failed to delete rent',
        error_save_owners: 'Failed to save property owners',
        error_update: 'Failed to update property',
        error_final_rent_created: 'Failed to save final contract',
        error_delete: 'Failed to delete property',
        error_retrieve: 'Failed to retrieve properties',
        error_download_images: 'Failed to download images'
    },
    clients: {
        error_retrieve: 'Failed to retrieve clients',
    },
    owners: {
        search_owners: 'search owners',
        add_owner: 'Add Owner',
        full_name: 'Full Name',
        contact_number: 'Contact Number',
        email: 'Email',
        address: 'Address',
        locality: 'Locality',
        post_code: 'Post Code',
        actions: 'Actions',
        status: 'Status',
        property_id: 'Property ID',
        type: 'Type',
        owners: 'Owners',
        entries: 'Entries',
        edit_owner: 'Edit Owner',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: '',
        error_update: 'Failed to update owner',
        error_delete: 'Failed to delete owner',
        error_retrieve: 'Failed to retrieve owner',
        success_created: 'Owner has been created',
        success_updated: 'Owner has been updated',
        error_create: 'Failed to create owner',

    },
    attributes: {
        attributes: 'Attributes',
        add_attribute: 'Add Attribute',
        search_attributes: 'search attributes',
        name: 'Name',
        category: 'Category',
        actions: 'Actions',
        search_add_category: 'Search or Add Category',
        add_new_category: 'Add this as new Category',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: '',
        success_created: 'Attribute has been created',
        success_updated: 'Attribute has been updated',
        error_create: 'Failed to create attribute',
        error_update: 'Failed to update attribute',
        error_delete: 'Failed to delete attribute',
        error_retrieve: 'Failed to retrieve attributes',
    },
    bookings: {
        bookings: 'bookings',
        id_number: 'ID Number',
        phone: 'Phone',
        name: 'Name',
        date: 'Date',
        time_from: 'Time From',
        time_to: 'Time To',
        ward: 'Ward',
        full_name: 'Full Name',
        time: 'Time',
        visitor_x_name: 'Visitor {x} Name',
        visitor_x_id: 'Visitor {x} ID',
        add_visitor: 'Add Visitor',
        remove_visitor: 'Remove Visitor',
        edit_booking: 'Edit Booking',
        booking_details: 'Booking Details',
        visitor_details: 'Visitor Details',
        patient: 'Patient',
        error_retrieve_calendar: 'Failed to retrieve patient calendar',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'search users',
        name: 'Name',
        email: 'Email',
        password: 'Password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: '',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
    },
    roles: {
        roles: 'Roles',
        add_role: 'Add Role',
        search_roles: 'search roles',
        name: 'Name',
        permissions: 'Permissions',
        actions: 'Actions',
        error_retrieve: 'Failed to retrieve roles',
        error_delete: 'Failed to delete role',
    },
    reports: {
        date_from: 'Date From',
        date_to: 'Date To',
        select_agent: 'Select Agent',
        ward_daily_visitors: 'Ward Daily Visitors',
        rentz_direct_statistics: 'Statistics',
        agent_statistics: 'Agent Statistics',
        export_report: 'Export report',
        select_date: 'Please select a date',
        select_date_and_agent: 'Please select a date and an agent',
        error_generate: 'Failed to generate report',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        enter_valid_email: 'Enter a valid email',
        failed_login: 'Login Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    error_upload_files: 'Failed to upload files',
    error_save_files: 'Failed to save files',
    add: 'Add',
    save: 'Save',
    upload: 'Upload',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    copy: 'Copy',
}
