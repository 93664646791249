import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from "../views/Main";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'main',
                component: Main,
            }
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    return next();
});

export default router;
