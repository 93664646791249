<template>
    <div class="main">
        <div class="center">
            <div class="toolbar">
                <font-awesome-icon :icon="['far','bold']"/>
                <font-awesome-icon :icon="['far','italic']"/>
                <font-awesome-icon :icon="['far','underline']"/>
                <font-awesome-icon :icon="['far','h1']"/>
                <font-awesome-icon :icon="['far','h2']"/>
                <font-awesome-icon :icon="['far','list']"/>
                <font-awesome-icon :icon="['far','list-ol']"/>
            </div>

            <div class="writing-wrapper">
                <input type="text" class="title" placeholder="Titlu" v-model="title">

                <div class="text-wrapper">
                    <div id="editableDiv" class="textarea" contenteditable="true" spellcheck="false" placeholder="Type or paste (Ctrl+V) your text here" v-html="highlight()"></div>
                </div>

                <button class="submit-button" @click="check">Ivverifika</button>
            </div>

            <div class="feedback-wrapper">
                <h1 class="feedback-title">Suġġerimenti</h1>
                <div v-if="!suggestions.length" class="errors-empty">Nothing to check yet. Start writing or upload a document to see the feedback.</div>
                <div v-else class="suggestions-wrapper">
                    <div class="suggestion-card" v-for="(suggestion, suggestion_index) in suggestionsArray">
                        <p class="card-title">SPELLING</p>
                        <div class="card-suggestions">
                            <p>{{suggestion.incorrect}}</p>
                            <font-awesome-icon :icon="['fal','arrow-right']"/>
                            <button @click="replaceText(suggestion_index, suggestion.index, hit)" v-for="(hit, index) in suggestion.hits">{{hit.word}}
                                <p v-if="index !== suggestion.hits.length-1">, </p>
                            </button></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <p class="word-counter">{{wordCount}} Kelma</p>
            <div class="engineered-text"><p>Engineered by</p><img src="../assets/arkafort-logo-grey.svg"></div>
        </div>
    </div>
</template>

<script>
    import algoliasearch from 'algoliasearch/lite';

    export default {
        name: "Main",
        computed: {
            wordCount() {
                if (this.text)
                    return this.text.split(' ').length;
                else
                    return 0;
            },
            suggestionsArray() {
                let suggestions = this.suggestions;

                suggestions.sort(function(a, b) {
                    return a.index - b.index;
                });

                return suggestions;
            }
        },
        data() {
            return {
                algoliaClient: null,
                algoliaSubscriptionsClient: null,
                title: null,
                text: null,
                text_temp: null,
                word: null,
                suggestions: [],
                index_errors: [],
                cursor_pos: 0
            }
        },
        methods: {
            // search() {
            //     this.text_temp = this.text;
            //     this.text = editableDiv.innerText;
            //
            //     if (this.text_temp) {
            //         // let text_temp = this.text_temp.split(' ');
            //         let text = this.text.split(' ');
            //
            //
            //
            //         if (text_temp.length !== text.length) {
            //             if (text_temp.length < text.length) {
            //                 for (let i = 0; i < text_temp.length; i++) {
            //                     if (text_temp[i] != text[i+1]) {
            //                         this.check(i + 1, text[i + 1]);
            //                     }
            //                 }
            //             }
            //         } else {
            //             for (let i = 0; i < text_temp.length; i++) {
            //                 if (text_temp[i] != text[i]) {
            //                     this.check(i, text[i]);
            //                 }
            //             }
            //         }
            //     }
            // },
            check() {
                this.index_errors = [];

                this.text = editableDiv.innerText;
                let text = this.text.split(/(?<=-)| (?<=,) | (?<=.) | (?<=\\') | /);

                this.suggestions = [];

                text.forEach((word, index) => {
                    this.algoliaSubscriptionsClient.search(word).then(({hits}) => {
                        if(hits.length !== 1) {
                            let error = true;

                            if(hits.length) {
                                hits.forEach(hit => {
                                    if (hit.word === word.toLowerCase()) {
                                        error = false;
                                    }
                                });

                                if (error)
                                    this.suggestions.push({index: index, incorrect: word, hits: hits});
                            }
                        }
                        else if(hits.length === 1) {
                            if(hits[0].word !== word)
                                this.suggestions.push({index: index, incorrect: word, hits: hits});
                        }

                        let hit_error = true;

                        if(hits.length)
                            hits.forEach(hit => {
                                if (hit.word === word.toLowerCase())
                                    hit_error = false;
                            });
                        else
                            hit_error = false;

                        if(hit_error)
                            this.index_errors.push(index);
                    });
                });
            },
            highlight() {
                let html = '';

                if (this.text) {
                    let arr = this.text.split(/(?<=-)| (?<=,) | (?<=.) | (?<=\\') | /);

                    for (let i = 0; i < arr.length; i++) {
                        if (this.index_errors.includes(i)) {
                            if(arr[i].charAt(arr[i].length - 1) == '-')
                                html += '<span style="background: #FCDADF; border-bottom: 2px solid #E8072A; padding-bottom: 2px;">' + arr[i] + '</span>';
                            else
                                html += '<span style="background: #FCDADF; border-bottom: 2px solid #E8072A; padding-bottom: 2px;">' + arr[i] + '</span>' + ' ';
                        } else
                            if(arr[i].charAt(arr[i].length - 1) == '-')
                                html += '<span>' + arr[i] + '</span>';
                            else
                                html += '<span>' + arr[i] + '</span>' + ' ';
                    }
                }

                return html;
            },
            replaceText(suggestion_index, text_index, hit) {
                let arr = this.text.split(/(?<=-)| (?<=,) | (?<=.) | (?<=\\') | /);
                arr[text_index] = hit.word;

                for(let i = 0; i < this.index_errors.length; i++) {
                    if(this.index_errors[i] === text_index)
                        this.index_errors.splice(i, 1);
                }

                this.suggestions.splice(suggestion_index, 1);

                let text = '';

                arr.forEach(word => {
                    if(word.charAt(word.length - 1) == '-')
                        text += word;
                    else
                        text += word + ' ';
                });

                this.text = text;
            }
        },
        mounted() {
            this.algoliaClient = algoliasearch(this.$algoliaId, this.$algoliaKey);
            this.algoliaSubscriptionsClient = this.algoliaClient.initIndex('words');
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        @apply w-full h-screen flex flex-col;

        .center {
            @apply flex flex-row pt-10 h-full;
            overflow-y: hidden;

            .toolbar {
                @apply flex flex-col w-1/4 px-4 my-auto;

                svg {
                    @apply text-lg text-grey-dark mb-8;

                    &:hover {
                        @apply cursor-pointer text-black;
                    }
                }
            }

            .writing-wrapper {
                @apply flex flex-col flex-1 mx-auto max-w-3xl;

                .title {
                    @apply text-xl font-bold text-grey-dark mb-10 px-12;

                    &:active, &:focus {
                        @apply outline-none;
                    }
                }

                .text-wrapper {
                    @apply w-full h-full rounded-lg p-8;
                    box-shadow: 0px 0px 30px #00000012;

                    .textarea {
                        @apply w-full h-full text-grey-dark;
                        resize: none;

                        &:active, &:focus {
                            @apply outline-none;
                        }
                    }

                    [contenteditable=true]:empty:before {
                        content: attr(placeholder);
                        pointer-events: none;
                        display: block; /* For Firefox */
                    }
                }

                .submit-button {
                    @apply font-bold mt-4 rounded mx-auto py-2 px-4 text-white;
                    background: #19C49C;
                }
            }

            .feedback-wrapper {
                @apply ml-auto flex flex-col w-1/4 pr-8;
                overflow-y: scroll;

                .feedback-title {
                    @apply mb-4 text-black text-xl font-bold;
                }

                .errors-empty {
                    @apply text-grey-dark;
                }

                .suggestions-wrapper {
                    @apply flex flex-col;

                    .suggestion-card {
                        @apply flex flex-col mb-8 rounded-md p-4 bg-white;
                        box-shadow: 0px 0px 30px #00000012;
                        border-left: solid 6px #FF2323;

                        .card-title {
                            @apply text-grey-dark text-sm mb-2;
                        }

                        .card-suggestions {
                            @apply flex flex-row flex-wrap;

                            & > p {
                                @apply mr-2 my-auto;
                            }

                            svg {
                                @apply text-grey-dark my-auto text-xs mr-2;
                            }

                            button {
                                @apply my-auto cursor-pointer mr-2 flex flex-row;

                                &:hover {
                                    @apply text-black font-bold;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer {
            @apply flex flex-row mt-auto w-full justify-between p-6 text-grey-dark;

            .word-counter {
                @apply text-xl;
            }

            .engineered-text {
                @apply flex flex-row;

                p {
                    @apply text-lg mr-2;
                }
            }
        }
    }
</style>