<template>
    <div class="app-container">
        <div class="main-wrapper">
            <main>
                <slot/>
            </main>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppLayout",
    }
</script>

<style lang="scss" scoped>
    .app-container {
        @apply grid h-screen overflow-y-hidden;

        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "menubar menubar" "sidebar content";

        .main-wrapper {
            @apply overflow-auto flex flex-col h-screen;

            grid-area: content;

            main {
                @apply flex-1;
            }
        }
    }
</style>